import React from 'react';
import { AccentColors } from '../../types';
import Card from '../ui/Card';
import styles from './UseCasesNavCard.module.scss';

interface UseCasesNavCardProps {
  title: string;
  href: string;
  src: string;
  color: AccentColors;
}

const UseCasesNavCard: React.SFC<UseCasesNavCardProps> = ({
  title,
  href,
  src,
  color,
}) => (
  <Card hoverColor={color}>
    <a href={href} className={[styles.inner, styles[color]].join(' ')}>
      <div className={styles.title}>{title}</div>
      <div className={styles.imageContainer}>
        <img alt="" role="presentation" className={styles.image} src={src} />
      </div>
    </a>
  </Card>
);

export default UseCasesNavCard;
