import React from 'react';
import MarketingLayout from '../components/layout/MarketingLayout';
import UseCasesChapters from '../components/use-cases/UseCasesChapters';
import UseCasesHeader from '../components/use-cases/UseCasesHeader';

const UseCasesPage: React.SFC = () => (
  <MarketingLayout title="Use Cases">
    <UseCasesHeader />
    <UseCasesChapters />
  </MarketingLayout>
);

export default UseCasesPage;
