import React from 'react';
import engineering from '../../images/use-cases/engineering.svg';
import operations from '../../images/use-cases/operations.svg';
import sales from '../../images/use-cases/sales.svg';
import yourTeam from '../../images/use-cases/your-team.svg';
import Row, { Box } from '../ui/Row';
import SimpleSection from '../ui/SimpleSection';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './UseCasesChapters.module.scss';

const UseCasesChapters: React.SFC = () => (
  <div>
    <div id="engineering" className={styles.chapter}>
      <Row even reversed verticalCentered>
        <div>
          <img alt="" role="presentation" src={engineering} />
        </div>
        <div>
          <h2>
            <Text darker>Engineering</Text>
          </h2>
          <Spacing top={5} bottom={10}>
            <Text medium>
              Slab is built by engineers, for engineers. While simple to use, it
              employs many advanced features and shortcuts to keep your coders
              ahead of the curve.
            </Text>
          </Spacing>
        </div>
      </Row>
      <Row even>
        <SimpleSection title="Stay Productive">
          Document git branching or code review processes, spread best
          practices, or share debugging tips to help everyone perform their
          best.
        </SimpleSection>
        <SimpleSection title="Never Slip Up">
          Nobody should deploy to production or restore from backups by memory.
          Keep runbooks on Slab for a canonical source of truth. When Murphy’s
          Law strikes, make sure your team and future hires learn from the
          experience with postmortems.
        </SimpleSection>
        <SimpleSection title="Rock Your Integrations">
          Mention GitHub or JIRA issues to get quick access to your tasks from
          Slab. Embed Lucidchart or Draw.io diagrams to visually enrich your
          ideas.
        </SimpleSection>
      </Row>
    </div>

    <div id="operations" className={styles.chapter}>
      <Row>
        <Box width={1 / 3}>
          <img alt="" role="presentation" src={operations} />
        </Box>
        <Box width={2 / 3}>
          <h2>
            <Text darker>Operations</Text>
          </h2>
          <Spacing top={5} bottom={10}>
            <Text medium>
              Run a tight ship by detailing the regular tasks and challenges
              your company faces in writing, so they can be referenced easily.
            </Text>
          </Spacing>
          <Row even>
            <SimpleSection title="Standard Operating Procedures">
              Systemize your business by defining processes and workflows that
              work. Guide your team with step-by-step instructions for
              efficient, consistent, and safe conduct.
            </SimpleSection>
            <SimpleSection title="Customer Success">
              Build a knowledge base of frequently asked questions, helpful
              how-tos, and a canonical source of truth. Stay in-step with your
              team so your customers always get the most accurate and up-to-date
              answers.
            </SimpleSection>
          </Row>
        </Box>
      </Row>
    </div>

    <div id="sales" className={styles.chapter}>
      <Row even reversed verticalCentered>
        <div>
          <img alt="" role="presentation" src={sales} />
        </div>
        <div>
          <h2>
            <Text darker>Sales</Text>
          </h2>
          <Spacing top={5} bottom={10}>
            <Text medium>
              Equip your sales team with in-depth information about your
              product, potential customers, and each of your competitors.
            </Text>
          </Spacing>
        </div>
      </Row>
      <Spacing top={4}>
        <Row even>
          <SimpleSection title="Build a Sales Playbook">
            Detail what to look for when sourcing prospects and qualifying
            leads. Help account executives get to the finish line with the most
            effective techniques for your product.
          </SimpleSection>
          <SimpleSection title="Prime Your Case">
            Prepare your team with the different needs and objections of each
            market segment. Access select success stories and social proof that
            speak best to the prospective buyer.
          </SimpleSection>
          <SimpleSection title="Look Sharp">
            Teach the strengths and weaknesses of your product and your
            competitors&apos; products. Arm your team with battle cards to win
            deals.
          </SimpleSection>
        </Row>
      </Spacing>
    </div>

    <div id="your-team" className={styles.chapter}>
      <Row>
        <Box width={1 / 3}>
          <img alt="" role="presentation" src={yourTeam} />
        </Box>
        <Box width={2 / 3}>
          <h2>
            <Text darker>Your Team</Text>
          </h2>
          <Spacing top={5} bottom={10}>
            <Text large>
              The most competitive teams are constantly learning. Spread
              knowledge consistently throughout your company as you scale.
            </Text>
          </Spacing>
          <Row even>
            <SimpleSection title="Onboarding">
              Ramp up new teammates quickly with everything they need to know to
              succeed in their roles. From the day they join, provide them with
              a step-by-step guide to perform.
            </SimpleSection>
            <SimpleSection title="Culture and Values">
              Enshrine your company mission, core values, and unique culture for
              all current and future team members. Always keep a canonical
              source in writing to refer back to.
            </SimpleSection>
          </Row>
        </Box>
      </Row>
    </div>
  </div>
);

export default UseCasesChapters;
