import React from 'react';
import engineering from '../../images/use-cases/engineering-nav.png';
import operations from '../../images/use-cases/operations-nav.png';
import sales from '../../images/use-cases/sales-nav.png';
import yourTeam from '../../images/use-cases/your-team-nav.png';
import PageHeader from '../layout/PageHeader';
import PageSection from '../ui/PageSection';
import Spacing from '../ui/Spacing';
import styles from './UseCasesHeader.module.scss';
import UseCasesNavCard from './UseCasesNavCard';

const UseCasesHeader: React.SFC = () => (
  <div>
    <PageSection>
      <PageHeader
        title="How Teams Use Slab"
        description="Learn how teams like yours unlock the full potential of their shared learnings and knowledge."
      />
    </PageSection>
    <Spacing vertical={10}>
      <PageSection>
        <div className={styles.navCards}>
          <div className={styles.navCardContainer}>
            <UseCasesNavCard
              title="Engineering Teams"
              href="#engineering"
              src={engineering}
              color="green"
            />
          </div>
          <div className={styles.navCardContainer}>
            <UseCasesNavCard
              title="Operations Teams"
              href="#operations"
              src={operations}
              color="blue"
            />
          </div>
          <div className={styles.navCardContainer}>
            <UseCasesNavCard
              title="Sales Teams"
              href="#sales"
              src={sales}
              color="red"
            />
          </div>
          <div className={styles.navCardContainer}>
            <UseCasesNavCard
              title="Your Team"
              href="#your-team"
              src={yourTeam}
              color="yellow"
            />
          </div>
        </div>
      </PageSection>
    </Spacing>
  </div>
);

export default UseCasesHeader;
